import React, { useState, useEffect } from "react";
import {
    Form,
    Button,
    Col,
    Row,
    Container,
    Card,
    InputGroup,
    Breadcrumb,
    FormControl,
    ProgressBar,
    Alert,
} from "react-bootstrap";
import "./Checkout.css";
import { Link, useLocation } from "react-router-dom";
import razorPay from "../../assets/images/razorpay.png";
import { useNavigate } from "react-router-dom";
import {
    AiOutlineArrowRight,
    AiOutlineHome,
    AiOutlineTag,
} from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SecureLS from "secure-ls";
import axios from "axios";
import BreadcrumHeader from "../../Utils/BreadcrumHeader";
import { IoArrowForward } from "react-icons/io5";
import Loader from "../../Utils/loader/Loader";
import useLoader from "../../Utils/loader/useLoader";
import CryptoJS from "crypto-js";

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ls = new SecureLS({ encodingType: "aes" });
    const [iscreateAccount, setIscreateAccount] = useState(false);
    const keyId = process.env.REACT_APP_KEY_ID_DEV;
    const secretKeyDev = process.env.REACT_APP_SECRET_KEY_DEV;
    const secretKeyProd = process.env.REACT_APP_SECRET_KEY_PROD;

    const { isLoading, message, description, showLoader, hideLoader } =
        useLoader();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        scrollToTop();
        const username = ls.get("userEmail");
        if (!username) {
            setIscreateAccount(true);
        } else {
            getMyDetails(username);
        }
    }, [navigate]);

    const getMyDetails = async (username) => {
        try {
            const email = ls.get("userEmail");
            if (email) {
                const response = await axios.get(
                    `https://hubibee.com/api/client_api/authentication/account/index.php?email=${encodeURIComponent(
                        username
                    )}`
                );
                const myDetails = response.data;
                setFormValues((prevValues) => ({
                    ...prevValues,
                    email: myDetails.Email_Id || "",
                    phone: myDetails.Mobile_No || "",
                    firstName: myDetails.Firstname || "",
                    lastName: myDetails.Lastname || "",
                    address: myDetails.address1 || "",
                    address2: myDetails.address2 || "",
                    city: myDetails.city || "",
                    state: myDetails.states || "",
                    zip: myDetails.Zip || "",
                }));
            } else {
                console.error("User email is not found in local storage.");
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    var { item, isBuyNow, isCart, selectedSize } = location.state || {};
    if (!Array.isArray(item)) {
        item = [item];
    }

    if (isBuyNow && selectedSize) {
        item = item.map((product) => {
            const sizePrice = product.price.find(
                (priceEntry) => priceEntry.weight + "g" === selectedSize
            );
            return { ...product, price: sizePrice, qty: 1 }; // Add qty with value 1
        });
    }

    const baseUrl = "https://hubibee.com/";

    const totalQuantity = item.reduce(
        (total, item) => total + parseInt(item.qty ? item.qty : 1),
        0
    );
    const totalMRP = item.reduce(
        (total, item) =>
            total +
            parseFloat(item.price.basePrice) * parseInt(item.qty ? item.qty : 1),
        0
    );
    const totalDiscount = item.reduce(
        (total, item) =>
            total +
            (parseFloat(item.price.basePrice) -
                parseFloat(item.price.discountedPrice)) *
            parseInt(item.qty ? item.qty : 1),
        0
    );
    const totalAmount = item.reduce(
        (total, item) =>
            total +
            parseFloat(item.price.discountedPrice) *
            parseInt(item.qty ? item.qty : 1),
        0
    );

    const [formValues, setFormValues] = useState({
        email: "",
        emailValid: null,
        phone: "",
        phoneValid: null,
        firstName: "",
        firstNameValid: null,
        lastName: "",
        lastNameValid: null,
        country: "India",
        address: "",
        addressValid: null,
        address2: "",
        city: "",
        cityValid: null,
        state: "",
        stateValid: null,
        zip: "",
        zipValid: null,
        password: "",
        passwordValid: null,
        confirmPassword: "",
        confirmPasswordValid: null,
        confirmPasswordMatch: null,
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [shippingPrice, setShippingPrice] = useState(0);
    const [createAccount, setCreateAccount] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // Show/hide password toggle state
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Show/hide confirm password toggle
    const [passwordMatchError, setPasswordMatchError] = useState("");
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthText, setPasswordStrengthText] = useState("");
    useEffect(() => {
        const {
            email,
            phone,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            password,
            confirmPassword,
        } = formValues;
        if (
            validateEmail(email) &&
            validatePhoneNumber(phone) &&
            validateRequired(firstName) &&
            validateRequired(lastName) &&
            validateRequired(address) &&
            validateRequired(city) &&
            validateRequired(state) &&
            validateRequired(zip) &&
            (!createAccount ||
                (validateRequired(password) &&
                    validateRequired(confirmPassword) &&
                    validatePasswordMatch(password, confirmPassword)))
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [formValues, createAccount]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const re = /^\d{10}$/;
        return re.test(phone);
    };

    const validateZipCode = (zip) => {
        const re = /^\d{6}$/;
        return re.test(zip);
    };

    const validateRequired = (value) => {
        return value.trim() !== "";
    };

    const validatePasswordMatch = (password, confirmPassword) => {
        handleConfirmPasswordChange(password, confirmPassword);
        return password === confirmPassword;
    };

    const calculatePasswordStrength = (password) => {
        setShowPasswordStrength(password.length > 0);
        let strength = 0;
        if (password.length >= 8) strength += 1;
        if (/[a-z]/.test(password)) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/[0-9]/.test(password)) strength += 1;
        if (/[^a-zA-Z0-9]/.test(password)) strength += 1;

        setPasswordStrength(strength);

        if (strength === 5) {
            setPasswordStrengthText("Strong");
        } else if (strength >= 3) {
            setPasswordStrengthText("Medium");
        } else {
            setPasswordStrengthText("Weak");
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        let isValid = false;

        switch (id) {
            case "email":
                isValid = validateEmail(value);
                break;
            case "phone":
                isValid = validatePhoneNumber(value);
                break;
            case "zip":
                isValid = validateZipCode(value);
                break;
            default:
                isValid = validateRequired(value);
                break;
        }

        setFormValues((prevState) => ({
            ...prevState,
            [id]: value,
            [`${id}Valid`]: isValid,
        }));
    };

    const handleCheckboxChange = (e) => {
        setCreateAccount(e.target.checked);
    };

    // Toggle show/hide password
    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowConfirmPassword = () =>
        setShowConfirmPassword(!showConfirmPassword);

    const handleConfirmPasswordChange = (password, confirmPassword) => {
        if (password === confirmPassword) {
            setPasswordMatchError("Passwords do not match.");
        } else {
            setPasswordMatchError("");
        }
    };

    const fetchShippingPrice = async (zip) => {
        try {
            // Extract the prefix from the ZIP code
            const zipPrefix = zip.slice(0, 3); // Assuming ZIP code is a string

            // Check if the zipPrefix is between 67 to 69 (Kerala)
            const isKerala = zipPrefix >= "67" && zipPrefix <= "69";

            // Fixed shipping prices
            const keralaPrice = 70;
            const otherPrice = 150;

            // Calculate the total shipping price
            const shipping = item.reduce((acc, item) => {
                const deliveryPrice = isKerala ? keralaPrice : otherPrice;

                return deliveryPrice;
            }, 0);

            setShippingPrice(shipping);
        } catch (error) {
            console.error("Error fetching shipping price:", error);
        }
    };

    useEffect(() => {
        if (formValues.zip) {
            fetchShippingPrice(formValues.zip);
        }
    }, [formValues.zip]);

    async function generateHMAC(payload, secretKey) {
        const encoder = new TextEncoder();
        const key = await crypto.subtle.importKey(
            "raw",
            encoder.encode(secretKey),
            { name: "HMAC", hash: "SHA-256" },
            false,
            ["sign"]
        );

        const signature = await crypto.subtle.sign(
            "HMAC",
            key,
            encoder.encode(JSON.stringify(payload))
        );

        return Array.from(new Uint8Array(signature))
            .map((byte) => byte.toString(16).padStart(2, "0"))
            .join("");
    }

    const handlePayment = async () => {
        setIsFormValid(false);
        showLoader(
            "Processing Payment...",
            "Please wait while we redirect you to the payment gateway."
        );
        const sessionId = sessionStorage.getItem("sessionId");
        const username = sessionStorage.getItem("username");

        // Step 1: Attempt user registration if needed
        if (createAccount) {
            try {
                const registerResponse = await fetch(
                    "https://hubibee.com/api/client_api/authentication/register/checkoutregister.php",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ formValues }),
                    }
                );

                const registerData = await registerResponse.json();

                if (!registerData.success) {
                    alert(
                        registerData.message || "An error occurred during registration."
                    );
                    return; // Stop execution if registration fails
                }
            } catch (error) {
                console.error("Registration error: ", error.message);
                return; // Stop execution if registration throws an error
            }
        }

        // Step 2: Set up listener for messages from popup
        let orderPlaced = false; // Add a flag to track if the order has been placed
        const handleMessage = async (event) => {
            const data = event.data.response;
            if (data.status === "CHARGED" && !orderPlaced) {
                showLoader(
                    "Verifying Payment...",
                    "Please wait while we verify your payment."
                );
                const verifyPayment = await fetch(
                    "https://hubibee.com/payments//verifyPayment.php",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            paymentId: data.txn_detail.txn_uuid,
                            orderId: data.order_id,
                            payment_status: data.status,
                            amount: totalAmount + shippingPrice,
                        }),
                    }
                );

                const verifyData = await verifyPayment.json();
                if (verifyData.success) {
                    if (verifyData.transaction.status == data.status) {
                        showLoader(
                            "Placing the order...",
                            "Please wait while we place your order."
                        );
                        try {
                            const orderResponse = await fetch(
                                "https://hubibee.com/api/client_api/place/placeorder.php",
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        items: item,
                                        formValues,
                                        paid: totalAmount + shippingPrice,
                                        delivery_charges: shippingPrice,
                                        paymentId: data.txn_detail.txn_uuid, // Add the payment ID
                                        orderId: data.order_id, // Use the custom order ID
                                        payment_status: data.status, // Use the custom order ID
                                        razorpayOrderId: data.txn_detail.txn_id, // Add the Razorpay order ID,
                                        isBuyNow: isBuyNow,
                                        isCart: isCart,
                                        sessionId: sessionId || "na",
                                        username: username || "na",
                                    }),
                                }
                            );

                            const orderData = await orderResponse.json();

                            if (orderData.success) {
                                orderPlaced = true;
                                navigate("/placed", {
                                    state: {
                                        orderId: data.order_id,
                                        address: formValues,
                                        totalAmount: totalAmount + shippingPrice,
                                        shippingPrice: shippingPrice,
                                        orderDate: "2024-07-18",
                                        items: item,
                                    },
                                });
                            } else {
                                hideLoader();
                                alert("Something went wrong");
                            }
                        } catch (error) {
                            hideLoader();
                            alert("Something went wrong");
                        }
                    } else {
                        hideLoader();
                        alert("Payment verification failed!");
                    }
                } else {
                    hideLoader();
                    alert("Payment verification failed");
                }
            } else {
                showLoader("Verifying Payment...");
                if (!orderPlaced) {
                    try {
                        const orderResponse = await fetch(
                            "https://hubibee.com/api/client_api/place/placeorder.php",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    items: item,
                                    formValues,
                                    paid: totalAmount + shippingPrice,
                                    delivery_charges: shippingPrice,
                                    paymentId: data.txn_detail ? data.txn_detail.txn_uuid : "", // Add the payment ID
                                    orderId: data.order_id, // Use the custom order ID
                                    payment_status: data.status, // Use the custom order ID
                                    razorpayOrderId: data.txn_detail
                                        ? data.txn_detail.txn_id
                                        : "", // Add the Razorpay order ID,
                                    isBuyNow: isBuyNow,
                                    isCart: isCart,
                                    sessionId: sessionId || "na",
                                    username: username || "na",
                                }),
                            }
                        );

                        const orderData = await orderResponse.json();

                        if (orderData.success) {
                            orderPlaced = true;
                            hideLoader();
                        } else {
                            hideLoader();
                            alert("Something went wrong");
                        }
                    } catch (error) {
                        hideLoader();
                        alert("Something went wrong");
                    }
                }
                switch (data.status) {
                    case "NEW":
                        alert("Your payment was cancelled. Please try again.");
                        setIsFormValid(true);
                        break;
                    case "PENDING_VBV":
                        alert(
                            "Your payment is pending verification. If not confirmed or processed, the payment will be reversed."
                        );
                        setIsFormValid(true);
                        break;
                    case "AUTHORIZING":
                        alert("Your payment is being authorized. Please wait a moment.");
                        setIsFormValid(true);
                        break;
                    case "AUTHORIZATION_FAILED":
                        alert(
                            "Your payment authorization failed. Any amount deducted will be reversed by your bank. Please check your payment details and try again."
                        );
                        setIsFormValid(true);
                        break;
                    case "AUTHENTICATION_FAILED":
                        alert(
                            "Your payment authentication failed. Any amount deducted will be reversed by your bank. Please verify your identity and try again."
                        );
                        setIsFormValid(true);
                        break;
                    default:
                        alert(
                            "An unknown error occurred. Please try again later or contact your bank for more information."
                        );
                        setIsFormValid(true);
                        break;
                }
            }
            // Remove listener once message is received
            window.removeEventListener("message", handleMessage);
        };

        window.addEventListener("message", handleMessage);

        // Step 3: Create the payment session with HDFC
        try {
            const payload = {
                amount: totalAmount + shippingPrice,
                formValues,
                redirectUrl: "https://hubibee.com/payments/orderStatus.php",
                cancelUrl: "https://hubibee.com/payments/orderStatus.php",
            };

            // Generate HMAC signature
            const payloadString = JSON.stringify(payload);
            const signature = CryptoJS.HmacSHA256(
                payloadString,
                secretKeyDev
            ).toString(CryptoJS.enc.Hex);
            // Add signature to payload
            payload.signature = signature;

            const response = await fetch(
                "https://hubibee.com/payments/createSession.php",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );

            const sessionData = await response.json();

            if (sessionData.payment_url) {
                // Open payment gateway in a popup
                const width = 500;
                const height = 800;
                const left = window.screen.width / 2 - width / 2;
                const top = window.screen.height / 2 - height / 2;
                const paymentPopup = window.open(
                    sessionData.payment_url,
                    "Payment for Hubibee",
                    "width=" +
                    width +
                    ",height=" +
                    height +
                    ",top=" +
                    top +
                    ",left=" +
                    left
                );

                // Polling for popup closure
                const checkPopup = setInterval(() => {
                    if (paymentPopup.closed) {
                        clearInterval(checkPopup);
                    }
                }, 1000);
            } else if (sessionData.error === "Invalid signature") {
                alert(
                    "Payment request signature verification failed. Please try again."
                );
                hideLoader();
            } else {
                alert("Something went wrong");
            }
        } catch (error) {
            hideLoader();
            alert("Something went wrong");
        }
    };

    const [promoCode, setPromoCode] = useState("");

    const handleApplyPromoCode = () => {
        console.log(promoCode);
    };

    const handleTransactionComplete = (response) => {
        console.log(response);
    };

    const Breadcrumb = () => {
        return (
            <div className="breadcrumb-container">
                <div className="breadcrumb mb-0 px-3">
                    <Link to="/" className="breadcrumb-item text-brown me-3">
                        Home
                    </Link>
                    <Link to="/cart" className="breadcrumb-item text-brown me-3">
                        Cart
                    </Link>
                    <div className="breadcrumb-item text-brown me-3">Checkout</div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <section className="sectionView pb-5">
                <BreadcrumHeader route={Breadcrumb} title={"Checkout"} />
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col lg={7}>
                            <Card className="mb-3 rounded-4 bg-transparent border-0">
                                <Card.Body>
                                    <Form>
                                        {/* Contact Section */}
                                        <h5 className="fs-3 text-start">1. Contact Information</h5>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group controlId="email">
                                                    <Form.Control
                                                        type="email"
                                                        className={`bg-gray border-0 rounded-5 w-100 py-3 mb-3 ${formValues.emailValid === false
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                        placeholder="Enter e-mail address"
                                                        value={formValues.email}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.emailValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            Please enter a valid email address.
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="phone">
                                                    <Form.Control
                                                        type="number"
                                                        className={`bg-gray border-0 rounded-5 w-100 py-3 ${formValues.phoneValid === false
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                        minLength={"10"}
                                                        maxLength={"10"}
                                                        placeholder="Enter mobile number"
                                                        value={formValues.phone}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.phoneValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            Please enter a valid 10 Digit phone number without
                                                            +91.{" "}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {/* Personal Info Section */}
                                        <h5 className="fs-3 text-start w-100 mt-3">
                                            3. Personal Information
                                        </h5>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group controlId="firstName">
                                                    <Form.Control
                                                        type="text"
                                                        className={`w-100 bg-gray border-0 rounded-5 mt-3 py-3 ${formValues.firstNameValid === false
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                        placeholder="First name"
                                                        value={formValues.firstName}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.firstNameValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            First name is required.
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="lastName">
                                                    <Form.Control
                                                        type="text"
                                                        className={`w-100 bg-gray border-0 rounded-5 mt-3 py-3 ${formValues.lastNameValid === false
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                        placeholder="Last name"
                                                        value={formValues.lastName}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.lastNameValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            Last name is required.
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group controlId="country" className={"d-none"}>
                                            <Form.Control
                                                type="text"
                                                className="bg-gray border-0 rounded-5 me-3 mt-3 py-3"
                                                value={formValues.country}
                                                readOnly
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="address">
                                            <Form.Control
                                                type="text"
                                                placeholder="Door no, street address etc"
                                                className={`bg-gray border-0 rounded-5 me-3 mt-3  py-3 ${formValues.addressValid === false ? "is-invalid" : ""
                                                    }`}
                                                value={formValues.address}
                                                onChange={handleChange}
                                            />
                                            {formValues.addressValid === false && (
                                                <div className="invalid-feedback text-start">
                                                    Address is required.
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group controlId="address2">
                                            <Form.Control
                                                type="text"
                                                placeholder="Apartment, Suite, etc (optional) "
                                                className="bg-gray border-0  rounded-5 me-3 mt-3 py-3"
                                                value={formValues.address2}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Group controlId="city">
                                                    <Form.Control
                                                        type="text"
                                                        className={`bg-gray border-0 rounded-5 me-3 mt-3 py-3 ${formValues.cityValid === false ? "is-invalid" : ""
                                                            }`}
                                                        placeholder="City"
                                                        value={formValues.city}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.cityValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            City is required.
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group controlId="state">
                                                    <Form.Control
                                                        type="text"
                                                        className={`bg-gray border-0 rounded-5 me-3 mt-3 py-3 ${formValues.stateValid === false
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                        placeholder="State"
                                                        value={formValues.state}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.stateValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            State is required.
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group controlId="zip">
                                                    <Form.Control
                                                        type="number"
                                                        className={`bg-gray border-0 rounded-5 me-3 mt-3 py-3 ${formValues.zipValid === false ? "is-invalid" : ""
                                                            }`}
                                                        placeholder="Pincode"
                                                        value={formValues.zip}
                                                        onChange={handleChange}
                                                    />
                                                    {formValues.zipValid === false && (
                                                        <div className="invalid-feedback text-start">
                                                            6 Digit pincode is required.
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <h5 className="fs-3 text-start w-100 mt-3">
                                            3. Create Account?
                                        </h5>
                                        {iscreateAccount && (
                                            <Form.Group
                                                controlId="createAccountCheckbox"
                                                className="mt-3"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Create an account?"
                                                    className={"d-flex"}
                                                    checked={createAccount}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Form.Group>
                                        )}

                                        {createAccount && (
                                            <>
                                                <Row>
                                                    <Col className="position-relative">
                                                        <Form.Group controlId="password">
                                                            <Form.Control
                                                                type={showPassword ? "text" : "password"}
                                                                className={`bg-gray border-0 rounded-5 me-3 mt-3 py-3 ${formValues.passwordValid === false
                                                                        ? "is-invalid"
                                                                        : ""
                                                                    }`}
                                                                placeholder="Enter password"
                                                                value={formValues.password}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    calculatePasswordStrength(e.target.value);
                                                                }}
                                                            />
                                                            {formValues.passwordValid === false && (
                                                                <div className="invalid-feedback text-start">
                                                                    Password is required.
                                                                </div>
                                                            )}
                                                        </Form.Group>
                                                        <span
                                                            onClick={toggleShowPassword}
                                                            className="position-absolute end-0 translate-middle-y me-5"
                                                            style={{ top: "44px" }}
                                                        >
                                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </Col>
                                                    <Col className="position-relative">
                                                        <Form.Group controlId="confirmPassword">
                                                            <Form.Control
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                className={`bg-gray text-brown border-0 rounded-5 me-3 mt-3 py-3 ${formValues.confirmPasswordValid === false ||
                                                                        validatePasswordMatch === false
                                                                        ? "is-invalid"
                                                                        : ""
                                                                    }`}
                                                                placeholder="Confirm password"
                                                                value={formValues.confirmPassword}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                }}
                                                            />
                                                            {(formValues.confirmPasswordValid === false ||
                                                                validatePasswordMatch === false) && (
                                                                    <div className="invalid-feedback text-start">
                                                                        Passwords must match.
                                                                    </div>
                                                                )}
                                                        </Form.Group>
                                                        <span
                                                            onClick={toggleShowConfirmPassword}
                                                            className="position-absolute end-0 translate-middle-y me-5"
                                                            style={{ top: "44px" }}
                                                        >
                                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={5}>
                            <Card
                                className="mb-3 rounded-4 bg-transparent"
                                style={{ position: "sticky", top: "18vh" }}
                            >
                                <Card.Body>
                                    <h3 className="text-start fw-bold fs-3 pb-3">
                                        Order Summary
                                    </h3>
                                    <Row>
                                        <Col className="text-start">Quantity</Col>
                                        <Col className="text-end fs-5 fw-bold">
                                            {totalQuantity} item{totalQuantity > 1 ? "s" : ""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-start">Subtotal</Col>
                                        <Col className="text-end fs-5 fw-bold">
                                            ₹ {totalMRP.toFixed(2)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-start">Discount</Col>
                                        <Col className="text-end  fs-5 fw-bold">
                                            ₹ {totalDiscount.toFixed(2)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-start">Shipping</Col>
                                        <Col className="text-end  fs-5 fw-bold">
                                            {shippingPrice === 0
                                                ? "Enter Pincode to calculate"
                                                : `₹ ${shippingPrice.toFixed(2)}`}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mt-3">
                                        <Col className="text-start">Total Payable</Col>
                                        <Col className="text-end fs-5 fw-bold">
                                            <strong>
                                                ₹ {(totalAmount + shippingPrice).toFixed(2)}
                                            </strong>
                                        </Col>
                                    </Row>

                                    <hr />
                                    {/* <Form className="d-flex mt-3 py-3">
                                        <InputGroup className="w-75">
                                            <FormControl
                                                type="text"
                                                placeholder="Add Promo Code"
                                                className="bg-gray border-0  rounded-5 me-3"
                                                value={promoCode}
                                                onChange={(e) => setPromoCode(e.target.value)}
                                            />
                                        </InputGroup>
                                        <Button variant="dark" className="bg-black rounded-5 w-25 justify-content-center py-3 fw-bold" onClick={handleApplyPromoCode}>
                                            <AiOutlineTag size={20} className='me-3' /> Apply
                                        </Button>
                                    </Form> */}
                                    <Button
                                        disabled={!isFormValid}
                                        onClick={handlePayment}
                                        variant="dark"
                                        className="rounded-5 w-100 py-3 justify-content-center bg-black"
                                    >
                                        Place Order <IoArrowForward className="ms-2" />
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Loader show={isLoading} message={message} description={description} />
        </div>
    );
};

export default Checkout;
