import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';

function CollectionView() {
    const { collectionName } = useParams();
    const collection_name = collectionName.replace(/-/g, ' ');
    const baseURL = 'https://hubibee.com/';
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const [collectionItems, setCollectionItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortOption, setSortOption] = useState('');
    const [filterRating, setFilterRating] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = sessionStorage.getItem('username');
        if (!username) {
            if (sessionId) {
                fetchProduct(sessionId, 'na', collection_name);
            }
        } else {
            fetchProduct('na', username, collection_name);
        }
    }, [collection_name]);

    const fetchProduct = async (ipadd, email, collectionName) => {
        try {
            const response = await axios.get(`${baseURL}api/client_api/collections/getCollections.php`, {
                params: {
                    name: collectionName,
                    ipadd: ipadd,
                    email: email,
                }
            });
            const fetchedItems = response.data.data;
            setCollectionItems(fetchedItems);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const CustomBreadcrumb = () => (
        <div className='breadcrumb-container'>
            <div className='breadcrumb mb-0 px-3'>
                <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
                <Link to='/collections' className='breadcrumb-item text-brown me-2'>Collections</Link>
                <div className='breadcrumb-item text-brown me-2'>{collectionName}</div>
            </div>
        </div>
    );

    const sortCollectionItems = (items) => {
        if (sortOption === 'price-asc') {
            return [...items].sort((a, b) => a.price[0].discountedPrice - b.price[0].discountedPrice);
        } else if (sortOption === 'price-desc') {
            return [...items].sort((a, b) => b.price[0].discountedPrice - a.price[0].discountedPrice);
        } else if (sortOption === 'name-asc') {
            return [...items].sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortOption === 'name-desc') {
            return [...items].sort((a, b) => b.name.localeCompare(a.name));
        } else if (sortOption === 'date-asc') {
            return [...items].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        } else if (sortOption === 'date-desc') {
            return [...items].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
        return items;
    };

    const filterCollectionItemsByRating = (items) => {
        if (filterRating) {
            return items.filter(item => {
                const ratings = item.average_rating ? JSON.parse(item.average_rating) : [];
                const avgRating = ratings.length ? ratings.reduce((acc, review) => acc + review.rating, 0) / ratings.length : 0;
                return avgRating >= filterRating;
            });
        }
        return items;
    };

    const handleSort = (sortKey) => {
        if (sortKey === 'price-asc') {
            setSortOption('price-asc');
        } else if (sortKey === 'price-desc') {
            setSortOption('price-desc');
        } else if (sortKey === 'name-asc') {
            setSortOption('name-asc');
        } else if (sortKey === 'name-desc') {
            setSortOption('name-desc');
        } else if (sortKey === 'latest') {
            setSortOption('date-desc');
        } else if (sortKey === 'oldest') {
            setSortOption('date-asc');
        }
    };

    const handleFilterByRating = (rating) => {
        setFilterRating(rating);
    };

    let displayedItems = sortCollectionItems(filterCollectionItemsByRating(collectionItems));

    return (
        <section className='sectionView'>
            <BreadcrumHeader route={CustomBreadcrumb} title={collectionName} />
            <div className='container mt-3'>
                <div className='d-flex mb-3 justify-content-end'>
                    <div className='me-2'>
                        <DropdownButton
                            as={ButtonGroup}
                            variant={'secondary'}
                            title={'Sort By'}
                            className='rounded-5'
                            onSelect={(e) => handleSort(e)}
                        >
                            <Dropdown.Item eventKey="price-asc">Price: Low to High</Dropdown.Item>
                            <Dropdown.Item eventKey="price-desc">Price: High to Low</Dropdown.Item>
                            <Dropdown.Item eventKey="name-asc">Name: A to Z</Dropdown.Item>
                            <Dropdown.Item eventKey="name-desc">Name: Z to A</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>

                {isLoading ? (
                    <div>Loading....</div>
                ) : displayedItems.length > 0 ? (
                    <Row className="g-2">
                        {displayedItems.map((item, index) => (
                            <Col xs={6} md={6} lg={3} key={index}>
                                <ProductCard key={index} item={item} baseUrl={baseURL} wishlist={item.in_wishlist} cart={item.in_cart} />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <div>No items found.</div>
                )}
            </div>
        </section>
    );
}

export default CollectionView;
