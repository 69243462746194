import React, { useEffect } from 'react';
import './AboutUs.css';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AboutusImg from '../../assets/images/aboutus.jpg'
// Import images statically

function AboutUs() {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    return (
        <section>
            <Container className='mt-3 px-lg-4 px-md-3 px-sm-4'>
                <section className="p-t-5 p-b-120">
                    <div className="row pb-5">
                        <div className="col-sm-12 col-lg-4 col-md-12">
                            <div className="d-flex justify-content-center position-relative aboutus-image-container-top">
                                <div className="d-flex justify-content-center position-relative aboutus-image-container-bottom">
                                    <img src={AboutusImg} alt="Vision Image 1" className='img-instagram-style w-100 rounded-4' />
                                    <div className='aboutus-image-wrap-bottom'></div>
                                </div>
                                <div className='aboutus-image-wrap-top'></div>
                                <h4 className='title text-start text-black text-capitalise aboutus-header'>About <span className='gradient-text'>Us</span></h4>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-8 col-md-12">
                            <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                                <p className="stext-113 text-brown cl6 p-b-26 fw-bold fs-1" style={{ fontSize: '24px' }}>
                                    From the hive to the table...!
                                </p>
                                <p className="stext-113 text-brown cl6 p-b-26">
                                    Hubibee honey is the natural wild honey extracted from the bee hives placed near the Sidr Trees. It is extracted by the professional honey hunters and processed well to provide you the purest wild honey. Through scrupulous testing and careful handling, we ensure every bottle of Hubibee honey is the highest quality from the inside out: no additives, no preservatives, never pasteurized, crafted for exceptional taste, and non-sticky packaging. We believe in the best quality product so that you enjoy the potent and mystical delicacy of natural, exotic and pure honey to promote your good health and well-being in an ancient and natural way.
                                </p>
                                <p className="stext-113 text-brown cl6 p-b-26">
                                    Hubibee honey producers work on the motto of “Let’s Bee The Best Friend”. Hubibee honey producers are committed to producing the highest quality honey in collaboration with the National Horticulture Board.
                                </p>
                                <Tabs defaultActiveKey="History" id="product-tabs" className="container px-lg-4 px-md-4 px-sm-4 mb-3 d-flex justify-content-around align-items-center custom-tabs" variant="underline" >
                                    <Tab eventKey="History" title="Our History">
                                        Hubibee honey producers have a rich history rooted in a commitment to quality and collaboration. Established in partnership with the National Horticulture Board, Hubibee has always focused on producing the finest honey. Our journey began with a simple yet powerful motto, “Let’s Be The Best Friend”, which reflects our dedication to nurturing nature and delivering exceptional honey to our customers. Over the years, we have expanded our offerings to include a diverse range of honey types, each sourced from carefully cultivated fields. Our history is one of passion, hard work, and an unwavering commitment to excellence.

                                    </Tab>
                                    <Tab eventKey="Mission" title="Our Vision" >
                                        At Hubibee, our mission is to produce and deliver the highest quality honey, rich in goodness and nutritional value. We strive to be a trusted friend to both nature and our customers by ensuring that our honey is natural, pure, and beneficial. By guiding hardworking bees to collect nectar from distinct plants, we are able to offer a variety of honey types, each tailored to meet the unique tastes and health needs of our consumers. Our mission is to bring the purest and most natural honey from the hive to your table, ensuring that every jar is a testament to quality and care.

                                    </Tab>
                                    <Tab eventKey="Vision" title="FAQs">
                                        Our vision at Hubibee is to be recognized globally as a leader in natural honey production, known for our commitment to quality, sustainability, and innovation. We aim to create a world where natural and pure honey is a staple in every household, providing not just a delicious food product, but also a source of health and wellness. We envision expanding our product offerings and reaching new markets while maintaining our core values of purity, sustainability, and customer satisfaction. By fostering a strong relationship with nature and our customers, we aspire to continue growing as a brand synonymous with trust, quality, and excellence in honey production.
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </section>
    )
}

export default AboutUs;
