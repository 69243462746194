import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './Checkout.css';
import { Container, Card, Button, Table, Row, Col } from 'react-bootstrap';
import { CartContext } from '../../Utils/CartContext';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';

function Placed() {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchCartCount } = useContext(CartContext);

  const { orderId, totalAmount, items, address, shippingPrice } = location.state || {};
  const baseUrl = 'https://hubibee.com/';

  useEffect(() => {
    const handlePopState = () => {
      navigate('/'); // Navigate to home page on back button press
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    fetchCartCount()
  }, []);

  const handleViewOrder = () => {
    // Extract orderId and email from formValues
    const { email } = address;

    // Navigate to order-track with orderId and email as state
    navigate('/track-order', { state: { preorderid: orderId, preemail: email } });
  };

  const handleHome = () => {
    // Navigate to home page 
    navigate('/');
  };

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
          <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
          <div className='breadcrumb-item text-brown me-2'>cart</div>
        </div>
      </div>
    );
  };

  const totalQuantity = items.reduce((total, items) => total + parseInt(items.qty ? items.qty : 1), 0);
  const totalMRP = items.reduce((total, items) => total + parseFloat(items.price.basePrice) * parseInt(items.qty ? items.qty : 1), 0);
  const totalDiscount = items.reduce((total, items) => total + (parseFloat(items.price.basePrice) - parseFloat(items.price.discountedPrice)) * parseInt(items.qty ? items.qty : 1), 0);

  return (
    <div>
      <section className='sectionView'>
        <BreadcrumHeader route={Breadcrumb} title={'Order Placed Successfully!'} />
        <Container>
          <Card className="text-center mt-5 p-2 bg-transparent rounded-5 bill-card border-0">
            <Card.Body>
              <Row className='g-2'>
                <Col md={6} sm={12} lg={6} xs={12}>
                  <div style={{ position: 'sticky', top: '10vh' }}>
                    <h3 className='fw-bold text-start'>Thank you for your purchase!.</h3>
                    <p className='text-start'>Your order has been placed successfully. Your order ID is {orderId}.</p>
                    {/* //billing address */}
                    <h5 className='fw-bold text-start'>Billing Address</h5>
                    <p className='text-start mb-0'>{address.firstName + ' ' + address.lastName}</p>
                    <p className='text-start mb-0'>{address.address}</p>
                    <p className='text-start mb-0'>{address.address2}</p>
                    <p className='text-start mb-0'>{address.city}, {address.state} - {address.zip}</p>
                    <p className='text-start'>{address.phone}</p>
                    {/* tracking buttons */}
                    <div className='d-flex justify-content-around'>
                      <Button className='w-100 me-2' style={{ backgroundColor: '#FFC633', borderColor: '#FFC633' }} onClick={handleViewOrder}>Track Order</Button>
                      <Button variant="outline-dark" className='w-100 ms-2' onClick={handleHome}>Go to Home</Button>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={12} lg={6} xs={12}>
                  {/* order summary */}
                  <Card className='rounded-4 bg-white shadow' style={{ position: 'sticky', top: '18vh' }}>
                    <Card.Body>
                      <h5 className='text-start fw-bold pb-3'>Order Summary</h5>
                      <ul className="list-unstyled">
                        {items && items.map((item, index) => (
                          <li key={index} className="text-start mb-2">
                            <Row className="d-flex align-items-start">
                              <Col xs={6} s={4} md={4} lg={2} className="me-0">
                                <img src={baseUrl + item.images[0]} alt={item.name} className='w-100 rounded' />
                              </Col>
                              <Col xs={6} s={4} md={8} lg={10} className="d-flex flex-column align-self-center overflow-hidden">
                                <div>
                                  <div className="user-details">
                                    <p className="mb-0 text-start fw-bold">{item.name}</p>
                                  </div>
                                  <p className='price-before me-2 mb-0 text-small text-start d-flex align-items-center'>
                                    gram: {item?.price.weight}
                                  </p>
                                  <div>
                                    <div className='w-100'>
                                      <div>
                                        <p className='prod-price text-small fs-6 me-2 mb-0 fw-light text-start'>
                                          ₹ {item?.price.discountedPrice}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                      <Row>
                        <Col className='text-start'>Quantity</Col>
                        <Col className="text-end fs-5 fw-bold">{totalQuantity} item{totalQuantity > 1 ? 's' : ''}</Col>
                      </Row>
                      <Row>
                        <Col className='text-start'>Subtotal</Col>
                        <Col className="text-end fs-5 fw-bold">₹ {totalMRP?.toFixed(2)}</Col>
                      </Row>
                      <Row>
                        <Col className='text-start'>Discount</Col>
                        <Col className="text-end  fs-5 fw-bold">₹ {totalDiscount?.toFixed(2)}</Col>
                      </Row>
                      <Row>
                        <Col className='text-start'>Shipping</Col>
                        <Col className="text-end  fs-5 fw-bold">{shippingPrice === 0 ? 'Enter Pincode to calculate' : `₹ ${shippingPrice?.toFixed(2)}`}</Col>
                      </Row>
                      <hr />
                      <Row className="mt-3">
                        <Col className='text-start'>Total Paid</Col>
                        <Col className="text-end fs-5 fw-bold">
                          <strong>₹ {(totalAmount)?.toFixed(2)}</strong>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </div>
  );
}

export default Placed;
