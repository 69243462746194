import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import './VideoCarousel.css';
import { IoCloseCircle, IoShare, IoShareSocialOutline, IoVolumeHigh, IoVolumeMute } from 'react-icons/io5';
import logoImage from '../../assets/images/brand.png';

function VideoCarousel() {
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [expandedVideo, setExpandedVideo] = useState(null); // To track the expanded video
  const [expandedVideoIndex, setExpandedVideoIndex] = useState(null);
  const baseURL = 'https://hubibee.com/';
  const detailsRef = useRef(null);

  useEffect(() => {
    getCarouselVideos();
  }, []);

  const getCarouselVideos = async () => {
    try {
      const response = await axios.get(`${baseURL}api/client_api/Videos/index.php`);
      setVideos(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExpandView = (item) => {
    const index = videos.indexOf(item);
    setExpandedVideo(item);
    document.body.style.overflow = 'hidden'; // Disable scrolling
    setExpandedVideoIndex(index); // Set the index
  };

  const closeOverlay = () => {
    setExpandedVideo(null);
    document.body.style.overflow = ''; // Disable scrolling
  };

  // Handlers for touch gestures
  const handleTouchStart = (e) => {
    // Track the starting vertical touch position
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    // Track the ending vertical touch position
    setTouchEnd(e.changedTouches[0].clientY);

    // Ensure both touchStart and touchEnd have values
    if (touchStart && touchEnd) {
      // Calculate the swipe distance on the vertical axis (Y-axis)
      const swipeDistance = touchStart - touchEnd;

      // Check if the swipe distance is enough to detect an upward or downward swipe
      if (swipeDistance > 50) {
        // Swipe Down (Negative swipe distance means the touch moved down)
        navigateToNextVideo(); // Example action for swipe down
      } else if (swipeDistance < -50) {
        // Swipe Up (Positive swipe distance means the touch moved up)
        navigateToPreviousVideo(); // Example action for swipe up
      }
    }
  };

  // Navigate between videos
  const navigateToNextVideo = () => {
    const currentIndex = videos.indexOf(expandedVideo);
    if (currentIndex < videos.length - 1) {
      setExpandedVideo(videos[currentIndex + 1]);
    }
    if (expandedVideoIndex < videos.length - 1) {
      setExpandedVideoIndex(expandedVideoIndex + 1);
    }
  };

  const navigateToPreviousVideo = () => {
    const currentIndex = videos.indexOf(expandedVideo);
    if (currentIndex > 0) {
      setExpandedVideo(videos[currentIndex - 1]);
    }
    if (expandedVideoIndex > 0) {
      setExpandedVideoIndex(expandedVideoIndex - 1);
    }
  };

  // Handlers for mouse scroll navigation
  const handleScroll = (e) => {
    if (e.shiftKey && detailsRef.current) {
      // Horizontal scroll for product details
      detailsRef.current.scrollLeft += e.deltaY;
    } else if (!e.shiftKey) {
      // Vertical scroll to switch videos
      if (e.deltaY > 0) {
        navigateToNextVideo();
      } else {
        navigateToPreviousVideo();
      }
    }
  };

  // const handleScroll = (e) => {
  //   if (e.deltaY > 0) {
  //     // Scroll down - go to next video
  //     const currentIndex = videos.indexOf(expandedVideo);
  //     if (currentIndex < videos.length - 1) {
  //       setExpandedVideo(videos[currentIndex + 1]);
  //     }
  //   } else {
  //     // Scroll up - go to previous video
  //     const currentIndex = videos.indexOf(expandedVideo);
  //     if (currentIndex > 0) {
  //       setExpandedVideo(videos[currentIndex - 1]);
  //     }
  //   }
  // };

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  return (
    <div>
      <Container>
        <section className="pt-3">
          <Row className="g-0">
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="title text-black py-2 text-capitalise">
                    <span className="gradient-text">Watch and Shop</span>
                  </h4>
                </div>
              </div>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <div
                  style={{
                    position: 'relative',
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                    scrollBehavior: 'smooth',
                  }}
                >
                  {videos.slice(0, 6).map((item, index) => (
                    <Card
                      key={index}
                      className="d-inline-block me-2 mb-3 bg-transparent border-0"
                    >
                      <Card.Body className="p-0">
                        <div className="product-img-container rounded-3">
                          <video
                            src={baseURL + item.video_url} // Assuming item has a video_url field
                            alt="Gender Video"
                            autoPlay
                            muted
                            loop
                            style={{ height: '480px', width: '270px', objectFit: 'cover', }}
                            className="rounded-3"
                            onClick={() => handleExpandView(item)}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </section>
      </Container>

      {/* Modal for expanded video view */}
      {expandedVideo && (
        <div
          className="video-overlay"
          onWheel={handleScroll} // Handle scroll to navigate
          onTouchStart={handleTouchStart} // Swipe start
          onTouchEnd={handleTouchEnd}     // Swipe end
        >
          <div className="overlay-content">
            {/* Previous video preview */}
            {expandedVideoIndex > 0 && (
              <div
                className="preview-video d-none d-lg-block d-md-block"
              >
                <video
                  src={baseURL + videos[expandedVideoIndex - 1].video_url}
                  muted
                  loop
                  className="preview-video-previous mb-lg-0 mb-md-0"
                ></video>
              </div>
            )}

            <Card className="d-flex me-2 mb-lg-1 mt-lg-3 mb-md-1 mt-md-3 bg-transparent border-0 main-video">
              <Card.Body className="p-0 mb-0">
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                  className=""
                  ref={detailsRef}
                >
                  {/* Render the selected video */}
                  <video
                    src={baseURL + expandedVideo.video_url} // Use expanded video URL
                    alt="Expanded Video"
                    autoPlay
                    loop
                    muted={isMuted}
                    className="video-player-main"
                  >
                    Your browser does not support the video tag.
                  </video>
                  <div
                    className="position-absolute bg-dark p-1 rounded-2"
                    style={{ top: '10px', right: '10px' }}
                  >
                    {/* <IoShareSocialOutline color="#FFF" size={'24px'} className="me-2"   onClick={handleShare} /> */}
                    {isMuted ? <IoVolumeMute color="#FFF" size={'24px'} className="me-2" onClick={toggleMute} /> : <IoVolumeHigh color="#FFF" size={'24px'} className="me-2" onClick={toggleMute} />
                    }
                    <IoCloseCircle color="#FFF" size={'24px'} onClick={closeOverlay} />
                  </div>
                  <div
                    className="position-absolute p-1"
                    style={{ top: '10px', left: '10px' }}
                  >
                    <img
                      src={logoImage}
                      width="auto"
                      height="60"
                      className="d-inline-block align-top py-2"
                      alt="stylespotlightco"
                    />
                  </div>
                  {/* Render product details for the expanded video */}
                  <div
                    className="expanded-product-details"
                  >
                    {expandedVideo.product_details?.map((product, index) => (
                      <a href={`/product/${generateSlug(product?.name)}`} target='_blank' referrerPolicy='noreffer'
                        key={index}
                        className='text-decoration-none text-black' >
                        <Card
                          className="d-inline-block me-2 mb-4 bg-white border-0"
                          style={{ width: '300px' }}
                        >
                          <Card.Body className="p-0 d-flex">
                            <div className="rounded-0 w-75">
                              <Card.Img
                                src={baseURL + product.images}
                                alt="Product Image"
                                style={{ height: '10vh', objectFit: 'cover', width: '100%' }}
                                className="rounded-end-0 rounded-bottom-0"
                              />
                            </div>
                            <div className="px-2">
                              <Card.Title className="text-start mb-0 fw-bold text-wrap product-name" style={{ fontSize: '14px' }}>
                                {product.name}
                              </Card.Title>
                              <div className="d-flex">
                                <Card.Text className="text-start text-black product-price mb-0 me-3">
                                  Rs. {product.price.discountedPrice}
                                </Card.Text>
                                <span className="text-muted text-decoration-line-through product-price">
                                  Rs {product.price.basePrice}
                                </span>
                              </div>
                            </div>
                          </Card.Body>
                          <Card.Footer className="p-0">
                            <h6 className="bg-dark text-white p-2 mb-0">Shop Now</h6>
                            <p className="mb-0" style={{ fontSize: '10px' }}>
                              powered by <a href='https://w3builders.in/' target='_blank' referrerPolicy='noreferrer' className='text-primary'>w3builders</a>
                            </p>
                          </Card.Footer>
                        </Card>
                      </a>
                    ))}
                  </div>
                </div>
              </Card.Body>
            </Card>

            {/* Next video preview */}
            {expandedVideoIndex < videos.length - 1 && (
              <div
                className="preview-video d-none d-lg-block d-md-block"
              >
                <video
                  src={baseURL + videos[expandedVideoIndex + 1].video_url}
                  muted
                  loop
                  className="preview-video-next mt-lg-2 mt-md-2"
                ></video>
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
}

export default VideoCarousel;
