import React, { useState, useContext, useEffect } from 'react';
import { Card, Badge, Button } from 'react-bootstrap';
import { IoStar } from 'react-icons/io5';
import { TbShoppingCartPlus, TbShoppingCartCheck, TbHeartPlus, TbHeartCheck } from "react-icons/tb";
import { AiOutlineHeart, AiOutlineLoading3Quarters, AiOutlineShoppingCart } from "react-icons/ai";
import axios from 'axios';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate } from 'react-router-dom';
import './ProductCard.css';
import Swal from 'sweetalert2'
import SecureLS from "secure-ls";
import StarRating from '../../Utils/StarRating';

const ProductCard = ({ item, baseUrl, wishlist, cart }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const navigate = useNavigate();


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(wishlist === 1);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const baseURL = 'https://hubibee.com/'
    const quantity = 1;

    const handleAddToCart = async () => {
        setShowLoadingIconCart(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails.prodcode, quantity: quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails.prodcode, quantity: quantity });

        if (success) {
            // console.log(`Added ${item.name} to cart`);
            setShowLoadingIconCart(false);
            setIsAddedToCart(true);

            Swal.fire({
                title: "Success!",
                text: `${item.name} has been added to your cart.`,
                icon: "success",
                cancelButtonText: "Continue Shopping",
                confirmButtonText: "Go to Cart",
                showCancelButton: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    // If confirmed, navigate to the cart page
                    navigate('/cart');
                }
            });

        } else {
            console.error('Failed to add to cart');
            setShowLoadingIconCart(false);
        }
    };

    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            // console.log(`Added ${item.name} to wishlist`);
            setShowLoadingIconWishlist(false);
            setIsAddedWishlist(true);
        } else {
            console.error('Failed to add to wishlist');
            setShowLoadingIconWishlist(false);
        }
    };


    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${item.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-').replace(/%/g, 'percent')    // Replace % with "percent" for safe URL encoding
        ;
    };

    return (
        <>
            <Card className="custom-card bg-transparent mx-2">
                <div className='product-image-item'>

                    <a href={`/product/${generateSlug(item?.name)}`} state={{ item }}>
                        <Card.Img
                            className='product-image rounded-4'
                            src={baseURL + item?.images[0]}
                        />
                    </a>

                    <div className='product-image-item-wrap'>
                        <button disabled={item?.price[0]?.inStock ? false : true} className="btn products-add-to-cart-btn d-flex align-items-center justify-content-center rounded-5"
                            onClick={handleAddToCart}
                        >
                            {item?.price[0]?.inStock ? 'Add to Cart' : 'Out of stock'}
                        </button>
                    </div>
                </div>

                <Card.Body className='px-0'>
                    <Card.Title className='prod-name elipses text-start mb-0'>
                        <a href={`/product/${generateSlug(item.name)}`} className='text-decoration-none text-black fw-medium'>
                            {item?.name}
                        </a>
                    </Card.Title>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <StarRating rating={String(item?.price?.[0]?.average_rating)} />
                                    <span className='ms-2 fw-bold fs-6'>{item.price[0].average_rating ? item.price[0].average_rating : 0}/5</span>
                                </div>
                            </div>
                            <div>
                                <span className='me-2' style={{ fontSize: '1.2rem', fontWeight: 'bold' }} >₹ {item.price[0].discountedPrice}</span>
                                <span style={{ textDecoration: 'line-through', color: 'grey', fontSize: '0.9rem' }} >₹ {item.price[0].basePrice}</span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ProductCard;
