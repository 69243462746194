import React from 'react';
import { Container } from 'react-bootstrap';
import './Home.css';
import Header from '../../components/Header/Header';
import Sliders from '../../components/Sliders/Sliders';
import OurFragrances from '../../components/OurFragrances/OurFragrances';
import Features from '../../components/Features/Features';
import Search from '../../components/Search/Search'
import OurProducts from '../../components/Products/OurProducts';
import StoreInfo from '../../components/StoreInfo/StoreInfo';
import VideoCard from '../../components/VideoCard/VideoCard';
import ShopByGender from '../../components/ShopByGender/ShopByGender';
import Subscribe from '../../components/Subscribe/Subscribe';
import CouponCodeSlider from '../../components/CouponCodeSlider/CouponCodeSlider';
import Testimonials from '../../components/Testimonials/Testimonials';
import BlogsHome from '../../components/Blogs/BlogsHome';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import AboutUs from '../../components/AboutUs/AboutUs';
import MoreInfo from '../../components/MoreInfo/MoreInfo';
import VideoCarousel from '../../components/VideoCarousel/VideoCarousel';
const components = [
  { component: <Sliders key="Sliders" />, key: 'Sliders' },
  { component: <Features key="Features" />, key: 'Features' },
  { component: <AboutUs key="AboutUs" />, key: 'AboutUs' },
  { component: <OurProducts key="OurProducts" />, key: 'OurProducts' },
  { component: <VideoCarousel key="VideoCarousel" />, key: 'VideoCarousel' },
  { component: <Testimonials key="Testimonials" />, key: 'Testimonials' },
  { component: <MoreInfo key="MoreInfo" />, key: 'MoreInfo' },
  { component: <BlogsHome key="BlogsHome" />, key: 'BlogsHome' }
];

function Home() {
  return (
    <div className='sectionView'>
      {components.map(({ component, key }) => (
        <div key={key} data-key={key}>
          {component}
        </div>
      ))}
    </div>
  );
}

export default Home;
