import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Container } from 'react-bootstrap';
import Slider from 'react-slick';
import './Sliders.css';
import { Link } from 'react-router-dom';
import { IoArrowBack, IoArrowBackOutline, IoArrowForward, IoArrowForwardOutline, IoChevronBack, IoChevronForward } from 'react-icons/io5';


function Sliders() {

    const [sliders, setSliders] = useState([])
    const [activeSlide, setActiveSlide] = useState(0);


    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 800,
        swipe: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        adaptiveHeight: false,
        arrows: false,
        fade: true,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        centerMode: false,
    };

    const baseURL = 'https://hubibee.com/';

    useEffect(() => {
        getSliders();
    }, [])

    const getSliders = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/Sliders/index.php`);
            setSliders(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // console.log(item.slider_type === 'Welcome to Hubibee' ? '' : (item.slider_type === 'promotion' ? '' : (item.slider_type === 'product' ? `${item.slider_type}/${generateSlug(item.additional_data.product)}` : `${item.slider_type}/${generateSlug(item.additional_data.collection)}`)));

    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    return (
        <section>
            <div className="sliderDesktop">
                <Container fluid className='custom-slick-side-container'>
                    <div className='custom-slick-slide'>
                        <Slider
                            ref={slider => { sliderRef = slider; }}
                            {...sliderSettings}>
                            {sliders && sliders.map((item, index) => (
                                <Link
                                    to={`/${item.slider_type === 'Welcome to Hubibee' ? '' : (item.slider_type === 'promotion' ? '' : (item.slider_type === 'product' ? `${item.slider_type}/${generateSlug(item.additional_data.product)}` : `${item.slider_type}/${generateSlug(item.additional_data.collection)}`))}`}
                                    key={index} className="slide-item">
                                    <img
                                        src={baseURL + item.image_path}
                                        alt="Slide 1"
                                        className='sliderImages'
                                    />
                                    <div className="overlay-text mx-5">
                                        <Badge bg="light" text="dark" className='p-2 rounded-4 slider-badge mb-2'>{item.slider_type}</Badge>
                                        <h1 className='slider-header w-75'><span className='fw-bold'>{item.additional_data.title}</span></h1>
                                        <p className='slider-desc mb-2 w-75'>{item.additional_data.description}</p>
                                        <button className="btn btn-yellow me-2 fw-bold rounded-5 px-5" >
                                            Order Now
                                        </button>
                                    </div>
                                </Link>
                            ))}
                        </Slider>
                        <div className='wrap'></div>
                    </div>
                    <div className='slick-custom-arrows'>
                        <button className="btn me-2 swiper-arrows" onClick={previous}>
                            <IoArrowBackOutline color='#431E06' size={25} />
                        </button>
                        <button
                            style={{ border: '2px solid #fff' }}
                            className="btn swiper-arrows"
                            onClick={next}>
                            <IoArrowForwardOutline color='#431E06' size={25} />
                        </button>
                    </div>
                </Container>
            </div>

            <div className="sliderMobile position-relative">
                <Slider {...sliderSettings}>
                    {sliders && sliders.map((item, index) => (
                        <Link
                            to={`/${item.slider_type === 'Welcome to Hubibee' ? '' : (item.slider_type === 'promotion' ? '' : (item.slider_type === 'product' ? `${item.slider_type}/${generateSlug(item.additional_data.product)}` : `${item.slider_type}/${generateSlug(item.additional_data.collection)}`))}`}
                            key={index} className="slide-item">
                            <img
                                src={baseURL + item.mobile_image_path}
                                alt="Slide 1"
                                className='sliderImages'
                            />
                            <div className="overlay-text">
                                <Badge bg="light" text="dark" className='p-2 rounded-4 slider-badge mb-2'>{item.slider_type}</Badge>
                                <h1 className='slider-header w-100'><span className='fw-bold'>{item.additional_data.title}</span></h1>
                                <p className='slider-desc mb-2 w-100'>{item.additional_data.description}</p>
                                <button className="btn btn-yellow me-2 fw-bold rounded-5 px-5" >
                                    Order Now
                                </button>
                            </div>
                        </Link>
                    ))}
                </Slider>
            </div>
        </section>
    )
}

export default Sliders
