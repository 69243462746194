import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Container, Row, Form, Button, Modal } from 'react-bootstrap';
import './Trackorder.css';
import moment from 'moment';
import { FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import BreadcrumHeader from '../../Utils/BreadcrumHeader';
import { IoArrowForwardOutline } from 'react-icons/io5';

function TrackOrder() {
  const location = useLocation();
  const { preorderid, preemail } = location.state || {};
  const [orderId, setOrderId] = useState(preorderid || '');
  const [email, setEmail] = useState(preemail || '');
  const [orderData, setOrderData] = useState([]);
  const [isTrackSidebarOpen, setTrackSidebarOpen] = useState(false);
  const [isDetailsSidebarOpen, setDetailsSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const baseURL = 'https://hubibee.com/';

  useEffect(() => {
    // Trigger form submission if preorderid or preemail is provided
    if ((preorderid && preemail) || (orderId && email)) {
      handleSubmit();
    }

    // This effect is now only responsible for managing the body scroll class
    if (isTrackSidebarOpen || isDetailsSidebarOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isTrackSidebarOpen, isDetailsSidebarOpen]);

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    if (!orderId || !email) return; // Prevent submission if either field is empty

    try {
      const OrderData = { orderId, email };
      const response = await axios.post('https://hubibee.com/api/client_api/Orders/OrderTracking/index.php', OrderData, {
        headers: { 'Content-Type': 'application/json' }
      });
      setOrderData(response.data.data);
    } catch (error) {
      console.error('Error fetching order data:', error);
      // Optionally, show user feedback here
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM D[,] h:mm A');
  };

  const handleTrackClick = (item) => {
    setSelectedOrder(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };

  const handleDetailsClick = (order) => {
    setSelectedOrder(order);
    setDetailsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    console.log('closing')
    setTrackSidebarOpen(false);
    setDetailsSidebarOpen(false);
  };

  const Breadcrumb = () => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb mb-0 px-3'>
          <Link to='/' className='breadcrumb-item text-brown me-2'>Home</Link>
          <div className='breadcrumb-item text-brown me-2'>cart</div>
        </div>
      </div>
    );
  };

  return (
    <section className='sectionView'>
      <BreadcrumHeader route={Breadcrumb} title={'Track your orders!'} />
      <Container>
        <Row style={{ minHeight: '80vh' }}>
          {/* Form Section */}
          <Col md={4}>
            <div style={{ position: 'sticky', top: '10vh' }}>
              <h6 className='text-black'>Track Your Order</h6>
              <Form onSubmit={handleSubmit}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="formOrderId"
                    placeholder="Enter Order ID"
                    value={orderId}
                    onChange={handleOrderIdChange}
                    required
                    className="rounded-5"
                  />
                  <Form.Label htmlFor="formOrderId">Order ID</Form.Label>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    type="email"
                    id="formEmail"
                    placeholder="Enter Email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    className="rounded-5"
                  />
                  <Form.Label htmlFor="formEmail">Email</Form.Label>
                </Form.Floating>

                <Button variant="dark" className="my-2 py-3 rounded-pill w-100 border-1" type="submit">
                  Track Order
                </Button>
              </Form>
            </div>
          </Col>
          <Col md={8}>
            {/* Order Details Section */}
            {orderData?.length > 0 ? (
              <div >
                <h6 className='text-black'>Found {orderData.length} orders for {orderId}</h6>
                <Row className='g-3'>
                  {orderData?.map((item, index) => (
                    <Col md={6} key={index}>
                      <Card onClick={() => handleTrackClick(item)} className="text-center w-100 h-100 border-0 trackorder-item" style={{ padding: 0, cursor: 'pointer' }}>
                        <Card.Header className="text-muted bg-transparent border-bottom-0">
                          <Row>
                            <Col sm={12} className="d-flex justify-content-between mb-2">
                              <div className="d-flex flex-column me-3 justify-content-start align-items-start">
                                <p className="mb-0 text-start f12 fw-bold">Order Placed</p>
                                <p className="mb-0 text-start f14">{formatDate(item.order_date)}</p>
                              </div>
                              <div className="d-flex flex-column me-3 justify-content-start align-items-start">
                                <p className="mb-0 text-start f12 fw-bold">Order ID</p>
                                <p className="mb-0 text-start f14">{item.orderid}</p>
                              </div>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Card.Body className="text-muted bg-transparent">
                          <Row className="pb-3">
                            <Col sm={12} className="mb-3">
                              <div className="d-flex align-items-center">
                                <div className="orderimage">
                                  <img
                                    src={baseURL + item.prod_image.replace(/^\.\.\.\.\//, '')}
                                    alt="Product Image"
                                    style={{ borderRadius: '15px', width: '60px', height: '60px', marginRight: '10px', objectFit: 'fill' }}
                                  />
                                </div>
                                <div className="user-details">
                                  <p className="mb-0 text-start fw-bold" style={{fontSize:'14px'}}>{item.prod_name}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                        <div className='trackorder-wrap'></div>
                        <button className="btn trackorder-btn d-flex align-items-center justify-content-center rounded-5">
                          {item.order_status[item.order_status.length - 1]?.status}
                        </button>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <h6 className='text-black'>Please enter your Order ID and Email to view your order details.</h6>
            )}
          </Col>
        </Row>

        {/* Tracking Sidebar */}
        <Modal show={showModal} onHide={handleCloseModal} scrollable centered>
          <Modal.Header closeButton>
            <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
              Tracking Details of &nbsp;<span>{selectedOrder?.orderid}</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="sidebar-menu-content">
              <div id="ordertrack" className='w-100'>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <Card className="shadow border-0 w-100  d-flex">
                      <h6 className="text-start px-3 py-2 text-dark">Order Status Updates</h6>
                      <div className="card-body d-flex flex-column pt-0">
                        <div className="timeline">
                          {selectedOrder?.order_status.map((item, index) => (
                            <div className={`tl-item ${index === selectedOrder.order_status.length - 1 ? 'active' : ''}`} key={index}>
                              <div className="tl-dot b-warning"></div>
                              <div className="tl-content">
                                <h6 className="text-start blue text-dark">{item.status}</h6>
                                <div className='text-start'>{item.desc}</div>
                                <div className="tl-date text-start text-muted mt-1">{formatDate(item.updated_at)}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Card>
                  </div>

                  <div className="col-md-12 mb-3">
                    <Card className="shadow border-0 h-100 w-100  d-flex">
                      <h6 className="text-start px-3 py-2 text-dark">Order Summary</h6>
                      <Card.Body className="d-flex flex-column pt-0">
                        <div className="cart_info">
                          <div className="row">
                            <div className="col-sm-12 align-items-start">
                              <div className="d-flex justify-content-between">
                                <p className="text-start mb-0">Quantity</p>
                                <p className="text-end mb-0">{selectedOrder?.prod_qty}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="text-start mb-0">Subtotal</p>
                                <p className="text-end mb-0">
                                  ₹ {selectedOrder?.prod_Price * selectedOrder?.prod_qty}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="text-start mb-0">Shipping</p>
                                <p className="text-end iscouponapplied  mb-0">₹ {selectedOrder?.delivery_charges}</p>
                              </div>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <p className="text-start  mb-0">Amount to Pay</p>
                                <p className="text-end  mb-0">₹ {(selectedOrder?.prod_Price * selectedOrder?.prod_qty) + selectedOrder?.delivery_charges}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-md-12 mb-3">
                    <Card className="h-100 w-100 shadow border-0 d-flex">
                      <h6 className="text-start px-3 py-2 text-dark">Order Details</h6>
                      <div className="card-body d-flex flex-column pt-0">
                        <h6 className="text-start blue f16">Customer Information</h6>
                        <p className="mb-0 text-start pb-0">Email: {selectedOrder?.email}</p>
                        <p className="mb-0 text-start pb-3">Shipping Address: {selectedOrder?.address1 + ', ' + selectedOrder?.address1 + ', ' + selectedOrder?.city + ', ' + selectedOrder?.zip + ', ' + selectedOrder?.states}</p>
                        <h6 className="text-start blue f16">Product Details</h6>
                        <div className="mb-3">
                          <div className="d-flex align-items-center">
                            <div className="orderimage">
                              <img
                                src={baseURL + selectedOrder?.prod_image.replace(/^\.\.\.\.\//, '')} // Removing ../../../../ from image URL
                                alt="Product Image"
                                style={{ borderRadius: '15px', width: '60px', height: '60px', marginRight: '10px', objectFit: 'fill' }}
                              />
                            </div>
                            <div className="user-details">
                              <p className="mb-0 text-start fw-bold">{selectedOrder?.prod_name}</p>
                              <p className="mb-0 text-start f14">Quantity: {selectedOrder?.prod_qty}</p>
                              <p className="mb-0 text-start f14">Price: {selectedOrder?.prod_Price}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </section>
  );
}

export default TrackOrder;
